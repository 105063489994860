import Config from './config.js'

var datiAgente = {
  getSummary: function (codage, year, opecodice) {
    var request = new Request(Config.erpBasePath + '/dati_agente/summaryData.php', {
        method: 'post',
        headers: new Headers({
            'Content-Type': 'application/json',
            Authorization: Config.erpAuthKey,
        }),
        body: JSON.stringify({
          codage: codage,
          year: year,
          ope_codice: opecodice,
        }),
    })
    var answer = fetch(request)
    return answer
  },

  getBudgets: function (codage, year, opecodice) {
    var request = new Request(Config.apiBasePath + '/agebudgets', {
        method: 'post',
        headers: new Headers({
            'Content-Type': 'application/json',
            Authorization: Config.erpAuthKey,
        }),
        body: JSON.stringify({
          codage: codage,
          year: year,
          ope_codice: opecodice,
        }),
    })
    var answer = fetch(request)
    return answer
  },

  getCliData: function (codage, year, opecodice) {
    var request = new Request(Config.erpBasePath + '/dati_agente/cliData.php', {
        method: 'post',
        headers: new Headers({
            'Content-Type': 'application/json',
            Authorization: Config.erpAuthKey,
        }),
        body: JSON.stringify({
          codage: codage,
          year: year,
          ope_codice: opecodice,
        }),
    })
    var answer = fetch(request)
    return answer
  },

}
export default datiAgente
